import {Input} from "../ui/input";
import {Button} from "../ui/button";
import {TFunction} from "i18next";
import {useState} from "react";
import {GetLicenceData} from "./licence.service";


const LicenceSearchComponent =({t}:{t:TFunction})=>{

    const [licenceNumber,setLicenceNumber] = useState<string>("");

    const fetchLicenceData = async (e:any)=>{
        const licence =   await GetLicenceData(licenceNumber)
        setLicenceNumber("")
        if(!licence){
            return
        }
        const element = document.createElement('a');
        element.href = licence.certificateLink
        element.target="_blank"
        element.style.display="none"
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }



    return (
        <div className="flex flex-row gap-0 w-full max-w-[480px] mb-12 group">
            <Input value={licenceNumber} onChange={(e)=>setLicenceNumber(e.target.value)} className="rounded-none rounded-l-md flex-grow focus-visible:ring-0 group-focus-visible:outline  focus-visible:ring-offset-0" placeholder={t('certificatePlaceholder')}/>
            <Button className="rounded-none rounded-r-md bg-black text-white flex-none " onClick={(e)=>{
                fetchLicenceData(e)
            }}>
                Go
            </Button>
        </div>
    )
}

export default LicenceSearchComponent