import { DataTable, DataTableDataSelectableEvent } from "primereact/datatable";
import { BiSearchAlt } from "react-icons/bi";
import { Column } from "primereact/column";
import { FilterMatchMode, PrimeReactProvider } from "primereact/api";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { ReactElement } from "react";

// //theme
import "primereact/resources/themes/lara-light-cyan/theme.css";

import "./DataTables.scss";

// import { AppDispatch} from "@/store/store";
// import {
//   DataTableState,
//   setSelectedRows,
// } from "@/store/features/datatable.slice";
// import { useDispatch } from "react-redux";
// import Link from "next/link";
// import {useAppSelector} from "@/store/hook.store";

export interface DataTableInfo {
  name: string;
  buttonTemplate?: ReactElement;
  columnInfo: ColumnInfo[];
}

export interface ColumnInfo {
  header: string;
  field?: string;
  filter?: boolean;
  template?: any;
  sortable?: boolean;
}

export default function DataTables({
  table_info,
  loading,
  data,
  disableIds,
  groupBy,
}: {
  table_info: DataTableInfo;
  loading: boolean;
  data: any;
  disableIds?: any[];
  groupBy?: string;
}) {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<any[] | null>(null);
  const [disableFields, setDisableFields] = useState(disableIds);

  useEffect(() => {
    setDisableFields(disableIds);
  }, [disableIds]);

  // const dataTableState = useAppSelector(
  //   (state) =>
  //     state.dataTableReducer.value
  //       .filter((x) => x.name == table_info.name)
  //       .map((x) => x.selectedRows)[0]
  // );
  // useEffect(() => {
  //   setSelectedRow(dataTableState);
  // }, [dataTableState]);
  //
  // const dispatch = useDispatch<AppDispatch>();

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    // @ts-ignore
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const fields: string[] = table_info.columnInfo
    .filter((x) => x.filter)
    .filter((x) => x.field)
    .map((value) => value.field!);

  const ButtonTemplate: ReactElement | undefined = table_info.buttonTemplate;

  const renderHeader = () => {
    return (
      <div className="flex justify-between px-4 items-center">
        <div className="flex items-center relative my-1">
          <BiSearchAlt className="absolute ms-6" />
          <input
            type="text"
            className="py-1.5 rounded w-[250px] pl-12 ring-blue-500 bg-gray-100 text-black dark:text-gray-300 dark:bg-[#1b1b29] focus:outline-blue-500 dark:focus:outline-blue-300"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search ..."
          />
        </div>
        <div>{ButtonTemplate}</div>
      </div>
    );
  };

  const header: ReactElement = renderHeader();
  // const onSelectedRow = (value: any[]) => {
  //   const payload: DataTableState = {
  //     name: table_info.name,
  //     selectedRows: value,
  //   };
  //   dispatch(setSelectedRows(payload));
  // };
  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;

  const isSelectable = (data: any) => {
    if (disableIds && disableIds.length > 0) {
      return !disableIds.includes(data.id);
    } else {
      return true;
    }
  };
  const isRowSelectable = (event: any) =>
    event.data ? isSelectable(event.data) : true;

  const rowClassName = (data: any) =>
    isSelectable(data) ? "" : "p-disabled-row";
console.log(data,"datainMaincolumn");
  return (
    // <PrimeReactProvider>
    <DataTable
      value={data}
      tableStyle={{ minWidth: "50rem" }}
      paginator
      rows={50}
      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      currentPageReportTemplate="{first} to {last} of {totalRecords}"
      paginatorLeft={paginatorLeft}
      paginatorRight={paginatorRight}
      rowsPerPageOptions={[5, 10, 25, 50]}
      dataKey="id"
      filters={filters}
      loading={loading}
      sortMode="multiple"
      removableSort
      globalFilterFields={fields}
      header={header}
      emptyMessage="No Data found..."
      selection={selectedRow}
      groupRowsBy={groupBy}
      rowGroupMode={groupBy ? "rowspan" : undefined}
      // onSelectionChange={(e) => onSelectedRow(e.value)}
      selectionMode={"checkbox"}
      scrollable
      scrollHeight="1300px"
      reorderableColumns
      isDataSelectable={isRowSelectable}
      rowClassName={rowClassName}
    >
      {/*<Column rowReorder style={{ width: '3rem' }} />*/}
      <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
      {table_info.columnInfo.map((value, index) => {
        const isSortable: boolean = value.sortable ? value.sortable : false;
        return (
          <Column
            field={value.field}
            body={value.template}
            sortable={isSortable}
            header={value.header}
            key={index + value.header}
          ></Column>
        );
      })}
      {/*{dynamicColumns}*/}
    </DataTable>
    // </PrimeReactProvider>
  );
}
