import {Bar, BarChart, XAxis, YAxis} from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "../../ui/chart"
import {cn} from "../../../lib/utils";
import {GetAllMonthlyReportBarCharts} from "../../../Utils/reports-mock.service";
import { useEffect, useState } from "react";
import { GetChartData, TChartData } from "./chart.services";



const chartConfig: ChartConfig = {
    submitted: {
        label: "Submitted",
        color: "#111111",
    },
    approved: {
        label: "Approved",
        color: "#FFAA17",
    },
    rejected:{
        label: "Rejected",
        color: "#FF584E",
    }
}
export default function ApprovalCharts() {

    const [chartData,setChartData] = useState<TChartData[]>([])

    useEffect(()=>{
        GetChartData().then(res=>{
            setChartData(res)
        })
    },[])
    

    const chartsDataTicks = (data:any[]) => {
        const keys = Object.entries(chartConfig).map(([key,_])=>key)
        const maxPerKey = keys.map((value)=>{
            return Math.max(...data.map(x=>x[value] as number))
        })
        const maxValue = Math.max(...maxPerKey)
        const nearsMaxValue = Math.ceil(maxValue/10000)*10000
        let tickArray:number[] = []
        const interval = (maxValue >50000) ? 10000:5000;
        for(let i=0; i<=nearsMaxValue; i=i+interval){
            tickArray.push(i)
        }
        return tickArray
    }
    return (
        <div className="bg-white px-8 py-4 rounded-xl shadow-lg">
            <div className="flex flex-row justify-between gap-4 items-center my-3">
                <h4>Last Six Month Approvals</h4>
                <ul className={cn("flex flex-col sm:flex-row items-center gap-4")}>
                    {
                        Object.entries(chartConfig).map(([key, value], index) => (
                            <li key={index} className="text-nowrap typo-paragraph1"><span
                                className="inline-flex size-3 rounded my-auto mr-2 "
                                style={{backgroundColor: value.color}}></span>{value.label}
                            </li>
                        ))
                    }

                </ul>
            </div>


            <ChartContainer config={chartConfig}>


                <BarChart accessibilityLayer
                          data={chartData.sort((value1, value2) => {
                              const date1 = new Date(value1.date).getTime()
                              const date2 = new Date(value2.date).getTime()

                              return date1 - date2
                          })}>
                    <XAxis
                        dataKey="date"
                        tickLine={false}
                        axisLine={true}
                        tickFormatter={(value) => {
                            const date = new Date(value)
                            return date.toLocaleDateString('default', {month: 'short'})
                        }}
                    />
                    <YAxis
                        ticks={chartsDataTicks(chartData)}
                        tickLine={false}
                        axisLine={true}
                        tickFormatter={(value) => {
                            let formatter = Intl.NumberFormat('en', {notation: 'compact'});
                            return formatter.format(value)
                        }}
                    />
                    <ChartTooltip
                        cursor={false}

                        content={<ChartTooltipContent
                            labelFormatter={(value) => {
                                const date = new Date(value)
                                return date.toLocaleDateString('default', {month: 'long'})
                            }}
                            className="bg-white" indicator="dot"/>}
                    />
                    <Bar dataKey="submitted" fill="var(--color-submitted)" radius={10}/>
                    <Bar dataKey="approved" fill="var(--color-approved)" radius={10}/>
                    <Bar dataKey="rejected" fill="var(--color-rejected)" radius={10}/>
                </BarChart>
            </ChartContainer>
        </div>
    )
}